<template>
    <v-app>
        <div id="printSection"></div>
        <SMenuPcComp
            :menu="menu"
            :mini="mini"
        />
        <SMenuMobileComp
            :menu="menu"
        />
        <div
            :style="mini ? 'padding-left: 56px' : 'padding-left: 300px'"
            class="app-private__container"
        >
            <router-view
                :mini="mini"
                @clickmini="mini=!mini"
            />
        </div>
        <div class="app-private__user">
            <h1 class="app-title-dark text-center">
                {{iNameUser()}}
            </h1>
        </div>
        <OBtnFlotingComp
            v-if="isMobile()"
            @click="eUpScroll()"
        />
    </v-app>
</template>
<script>
import {
    OBtnFlotingComp
} from "../../components/organisms";
import {
    SMenuPcComp,
    SMenuMobileComp
} from "../../components/species";
import { animationScrollMixin } from "../../mixins";
export default {
    components: {
        SMenuPcComp,
        SMenuMobileComp,
        OBtnFlotingComp
    },
    mixins: [ animationScrollMixin ],
    data: () => ({
        mini: true,
        menu: [],
        routeSale: "/"
    }),
    methods: {
        loadMenu() {
            if (JSON.parse(localStorage.getItem("nazaret"))) {
                const categorie = JSON.parse(localStorage.getItem("nazaret")).user.categorie;
                if (categorie === "0") {
                    this.menu = [
                        {
                            title: "Control Diario",
                            icon: "mdi-account-cash",
                            submenu: [
                                {
                                    title: "Gastos",
                                    icon: "mdi-cash-minus",
                                    route: "/expenses",
                                }, {
                                    title: "Informaciones",
                                    icon: 'mdi-information',
                                    route: "/informations",
                                }, {
                                    title: "Pagos a realizar",
                                    icon: 'mdi-cash-refund',
                                    route: "/payments-user",
                                }, {
                                    title: "Cierre de caja",
                                    icon: 'mdi-cash-register',
                                    route: "/close-box-cash-register",
                                }
                            ]
                        },
                        {
                            title: "Control Admin",
                            icon: "mdi-account-details",
                            submenu: [
                                {
                                    title: "Gastos",
                                    icon: "mdi-cash-minus",
                                    route: "/expenses-admin",
                                }, {
                                    title: "Informaciones",
                                    icon: 'mdi-information',
                                    route: "/informations-admin",
                                }, {
                                    title: "Cierre de caja",
                                    icon: 'mdi-cash-register',
                                    route: "/close-box-admin",
                                }, {
                                    title: "Control Personal",
                                    icon: 'mdi-account-details',
                                    route: "/personal-control",
                                }, {
                                    title: "Destinar Pagos",
                                    icon: 'mdi-cash-refund',
                                    route: "/payments-admin",
                                }
                            ]
                        },
                        {
                            title: "Productos",
                            icon: "mdi-medical-bag",
                            route: "/products",
                            submenu: []
                        },
                        {
                            title: "Personas",
                            icon: "mdi-account-group",
                            submenu: [
                                {
                                    title: "Proveedores",
                                    icon: "mdi-account-tie",
                                    route: "/providers",
                                }, {
                                    title: "Clientes",
                                    icon: 'mdi-account-cash',
                                    route: "/clients",
                                }, {
                                    title: "Usuarios",
                                    icon: "mdi-account-key",
                                    route: "/users",
                                    submenu: []
                                }
                            ]
                        },
                        {
                            title: "Información",
                            icon: "mdi-information",
                            submenu: [
                                {
                                    title: "Configuraciones",
                                    icon: "mdi-cog",
                                    route: "/settings",
                                },
                                {
                                    title: "Cajas Registradora",
                                    icon: "mdi-cash-register",
                                    route: "/cash-registers",
                                }, {
                                    title: "Laboratorios",
                                    icon: 'mdi-medication-outline',
                                    route: "/laboratories",
                                }, {
                                    title: "Tipos Productos",
                                    icon: 'mdi-file-table-box-multiple-outline',
                                    route: "/types-products",
                                }/*, {
                                    title: "Configuración Factura",
                                    icon: "mdi-file-cog",
                                    route: "/setting-bill",
                                }, {
                                    title: "Reporte Facturas",
                                    icon: 'mdi-list-box',
                                    route: "/bills-report",
                                    submenu: []
                                }*/
                            ]
                        },
                        {
                            title: "Control Inventario",
                            icon: "mdi-format-list-group",
                            submenu: [
                                {
                                    title: "Inventario",
                                    icon: "mdi-format-list-text",
                                    route: "/inventories",
                                }, {
                                    title: "Inventario Manual",
                                    icon: 'mdi-file-table-outline',
                                    route: "/lots",
                                }, {
                                    title: "Entradas Inventario",
                                    icon: 'mdi-format-list-group-plus',
                                    route: "/entries-inventories",
                                }, {
                                    title: "Inventario de Baja",
                                    icon: 'mdi-delete-sweep-outline',
                                    route: "/low-inventories",
                                }, {
                                    title: "Expiración Inventario",
                                    icon: 'mdi-elevation-decline',
                                    route: "/expiring-inventory",
                                }
                            ]
                        },
                        {
                            title: "Ventas",
                            icon: "mdi-cash",
                            submenu: [
                                {
                                    title: "Vender",
                                    icon: "mdi-cash-register",
                                    route: this.routeSale,
                                },
                                {
                                    title: "Reporte de Ventas",
                                    icon: "mdi-cash-multiple",
                                    route: "/report-sale",
                                }, {
                                    title: "Ventas Anuladas",
                                    icon: 'mdi-cash-remove',
                                    route: "/canceled-sales",
                                }, /*{
                                    title: "Reporte de Facturas",
                                    icon: 'mdi-list-box',
                                    route: "/bills-report",
                                }*/
                            ]
                        },
                        {
                            title: "Compras",
                            icon: "mdi-cart-variant",
                            route: "/purchases",
                            submenu: [
                                /*{
                                    title: "Compras",
                                    icon: "mdi-cart-variant",
                                    route: "/purchases"
                                }, {
                                    title: "Pedidos",
                                    icon: 'mdi-cart-arrow-right',
                                    route: "/orders"
                                }*/
                            ]
                        },
                        {
                            title: "Estadísticas",
                            icon: "mdi-chart-bar",
                            submenu: [
                                {
                                    title: "Labs. más vendidos",
                                    icon: "mdi-chart-bar",
                                    route: "/statistics-laboratories",
                                }, {
                                    title: "Products más vendidos",
                                    icon: 'mdi-chart-bar',
                                    route: "/statistics-products",
                                }, {
                                    title: "Ventas anuales",
                                    icon: 'mdi-chart-bar',
                                    route: "/annual-statistics",
                                }
                            ]
                        }
                    ];
                } else {
                    this.menu = [
                        {
                            title: "Vender",
                            icon: "mdi-cash",
                            route: this.routeSale,
                            submenu: []
                        },
                        {
                            title: "Gastos",
                            icon: "mdi-cash-minus",
                            route: "/expenses",
                            submenu: []
                        }, {
                            title: "Informaciones",
                            icon: 'mdi-information',
                            route: "/informations",
                            submenu: []
                        }, {
                            title: "Cierre de caja",
                            icon: 'mdi-cash-register',
                            route: "/close-box-cash-register",
                            submenu: []
                        }, {
                            title: "Pagos a realizar",
                            icon: 'mdi-cash-refund',
                            route: "/payments-user",
                            submenu: []
                        }/*, {
                            title: "Reporte Facturas",
                            icon: 'mdi-list-box',
                            route: "/bills-report",
                            submenu: []
                        }*/
                    ];
                }
            }
        },
        iNameUser() {
            if (JSON.parse(localStorage.getItem("nazaret")))
                return JSON.parse(localStorage.getItem("nazaret")).user.full_name;
            return "--"
        },
        eUpScroll() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
        },
        isMobile() {
            return window.innerWidth <= 600;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.routeSale = "/sale-mobile";
        this.loadMenu();
    }
}
</script>
